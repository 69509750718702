import React from "react";
import { SvgIcon } from "@material-ui/core";
export const UpdateIcon = (props) => {
    const { color } = props;
    return (
        <SvgIcon
            width="20px"
            height="20px"
            style={{ margin: "4px" }}
            viewBox="0 0 20 20"
        >
            <g transform="translate(0.000000,20.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M44 164 c-18 -14 -18 -14 6 -3 31 14 36 19 24 19 -6 0 -19 -7 -30
-16z"/>
                <path d="M133 164 c25 -13 32 -25 28 -47 -3 -15 22 -2 27 14 3 8 2 11 -2 8 -4
-4 -18 4 -31 17 -13 13 -30 24 -37 24 -7 -1 -1 -8 15 -16z"/>
                <path d="M12 100 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
                <path d="M131 66 c-7 -8 -11 -25 -9 -38 2 -19 10 -24 41 -26 l38 -3 -3 38 c-3
32 -7 38 -29 41 -14 2 -31 -4 -38 -12z m49 -20 c0 -2 -6 -9 -14 -15 -11 -9
-16 -9 -22 0 -4 7 -3 9 4 5 6 -3 13 -2 17 4 6 10 15 14 15 6z"/>
                <path d="M29 53 c11 -22 47 -40 58 -29 4 3 -1 6 -10 6 -9 0 -26 10 -38 23 -21
22 -21 22 -10 0z"/>
            </g>
        </SvgIcon>
    );
};
